import {
  getInsuranceProducts,
  getInsuranceProductsBySubscription, getInsuranceUpdateQuote, sendOptOutRequest,
  updateInsurance,
  getInsuranceQuote,
} from 'api/insurance'
import { getInsuranceItems, getNoProtectionInsuranceItems } from 'checkout/selectors/view/insurance'
import getCheckoutInsuranceQuotes from 'checkout/selectors/view/getCheckoutInsuranceQuotes'
import getCheckoutInsuranceProducts from 'checkout/selectors/view/getCheckoutInsuranceProducts'
import { providerIsCoverGenius } from 'lib/insurance/insuranceHelpers'
import { API_CALL, RESET_INSURANCE } from './actionConstants'
import {
  FETCH_INSURANCE_PRODUCTS, FETCH_INSURANCE_QUOTE,
  FETCH_INSURANCE_UPDATE_QUOTE,
  OPT_OUT_OF_QUOTE,
  UPDATE_INSURANCE,
} from './apiActionConstants'
import { AppAction } from './ActionTypes'
import { isInMobileApp } from 'lib/web/deviceUtils'
import { getOrderInsuranceUpdateQuoteKey } from 'lib/order/orderInsuranceUtils'
import { getFullOrder } from 'api/order'
import { updateOrder } from './OrderActions'
import { getInsuranceProductListKey, getInsuranceQuoteKey, getLuxVerticals, insuranceTypeToInsuranceProductType } from 'lib/insurance/insuranceUtils'
import { pushWithRegion } from './NavigationActions'
import { selectShouldUseInsuranceMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'

export interface FetchQuoteParams {
  destinationCountries: Array<string>;
  coverAmount: number;
  startDate: string;
  endDate: string;
  travellers: App.Occupants | Array<App.Occupants>;
  currency?: string;
  cartId?: string;
  isCruise?: boolean;
  numberOfSeniors?: number;
  type: App.InsuranceProductType;
  /**
   * List of policy ids - used to fetch parts of a custom quote
   */
  customPolicyIds?: Array<string>;
}

export function fetchInsuranceQuote(
  productId: string,
  params: FetchQuoteParams,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const key = getInsuranceQuoteKey(productId, params)

    if (
      state.insurance.quotes[key] ||
      state.insurance.fetchingQuotes[key] ||
      state.insurance.fetchQuotesErrors[key]
    ) {
      // already have this quote or are already getting it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_INSURANCE_QUOTE,
      key,
      request: () => getInsuranceQuote(
        productId,
        selectShouldUseInsuranceMemberPrice(state),
        {
          currency: params.currency ?? state.geo.currentCurrency,
          regionCode: state.geo.currentRegionCode,
          coverAmount: params.coverAmount,
          destinationCountries: params.destinationCountries,
          startDate: params.startDate,
          endDate: params.endDate,
          travellers: params.travellers,
          isMobileApp: isInMobileApp(state.system.rawUserAgentString),
          isSpoofed: state.auth.account.isSpoofed,
          cartId: params.cartId,
          isCruise: params.isCruise,
          numberOfSeniors: params.numberOfSeniors,
          customPolicyIds: params.customPolicyIds,
          type: params.type,
          key,
          luxVerticals: getLuxVerticals(state.checkout.cart.items),
        }),
    })
  }
}

export function sendCheckoutInsuranceOptOut(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const insuranceItems = getInsuranceItems(state)
    const noProtectionInsuranceItems = getNoProtectionInsuranceItems(state)

    const noProtectionInsuranceProductType = noProtectionInsuranceItems.length > 0 && noProtectionInsuranceItems[0].insuranceType ?
      insuranceTypeToInsuranceProductType(noProtectionInsuranceItems[0].insuranceType) :
      undefined

    const shouldSendOptOut = (
      // Only cover genius wants opt outs
      providerIsCoverGenius(state.geo.currentRegionCode, noProtectionInsuranceProductType) &&
      // User isn't purchasing an item
      insuranceItems.length < 1 &&
      // User selected no protection
      noProtectionInsuranceItems.length > 0
    )

    if (!shouldSendOptOut) {
      return
    }

    const insuranceType = noProtectionInsuranceItems[0].insuranceType
    if (!insuranceType) return
    const insuranceProductType = insuranceTypeToInsuranceProductType(insuranceType)

    const quotes = getCheckoutInsuranceQuotes(state, insuranceProductType)
    const products = getCheckoutInsuranceProducts(state, insuranceProductType)
    if (quotes.length > 0 && products.length > 0) {
      // Simply take the first quote - logic taken from old implementation
      const quoteId = quotes[0].id

      // In case where the full protection product is present, XCover requires sending the opt out with this product.
      // Otherwise, we should send the first product id.
      const product = products.find(product => product.protectionType === 'full') ?? products[0]

      dispatch({
        api: OPT_OUT_OF_QUOTE,
        type: API_CALL,
        request: () => sendOptOutRequest(quoteId, [product.id]),
      })
    }
  }
}

export function fetchInsuranceProducts(
  type: App.InsuranceProductType,
  params: {
    isDomestic?: boolean,
    isCruise?: boolean,
    subscriptionId?: string,
  } = {},
): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()

    const key = getInsuranceProductListKey(type, params)

    if (state.insurance.productLists[key] ||
      state.insurance.fetchingProductLists[key] ||
      state.insurance.productListErrors[key]
    ) {
      // already attempted it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_INSURANCE_PRODUCTS,
      key,
      request: () => {
        if (params.subscriptionId) {
          return getInsuranceProductsBySubscription(params.subscriptionId, state.geo.currentRegionCode)
        } else {
          return getInsuranceProducts(
            state.geo.currentRegionCode,
            type,
            {
              isDomestic: params.isDomestic,
              isCruise: params.isCruise,
            })
        }
      },
    })
  }
}

export function resetInsurance() {
  return {
    type: RESET_INSURANCE,
  }
}

interface UpdateQuoteParams {
  startDate: string;
  endDate: string;
  travellers: Array<App.OrderInsuranceItemTraveller>;
  insuranceItem: App.OrderInsuranceItem;
  coverageAmount?: number;
}

export function fetchOrderInsuranceUpdateQuote(
  orderId: string,
  update: UpdateQuoteParams,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const key = getOrderInsuranceUpdateQuoteKey({
      endDate: update.endDate,
      startDate: update.startDate,
      itemId: update.insuranceItem.id,
      travellers: update.travellers,
      coverageAmount: update.coverageAmount ?? update.insuranceItem.coverAmount,
    })

    if (state.insurance.updateQuotes[key]) {
      // already tried to fetch it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_INSURANCE_UPDATE_QUOTE,
      request: () => getInsuranceUpdateQuote(orderId, {
        coverageAmount: update.coverageAmount ?? update.insuranceItem.coverAmount,
        endDate: update.endDate,
        startDate: update.startDate,
        subscriptionId: update.insuranceItem.subscriptionId,
        travellers: update.travellers,
      }),
      key,
    })
  }
}

interface InsuranceUpdateParams {
  updateId: string;
  coverAmount: number;
  transactionKey: string;
  total: number;
  operation: 'change_dates' | 'change_guest_details';
  paymentPlan?: App.PaymentPlan;
}

export function updateOrderInsurance(
  orderId: string,
  requestDetails: InsuranceUpdateParams,
): AppAction {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: UPDATE_INSURANCE,
      request: async() => {
        await updateInsurance(orderId, requestDetails)
        const updatedOrder = await getFullOrder(orderId)
        dispatch(updateOrder(updatedOrder))
        dispatch(pushWithRegion(`/account/my-escapes/order/${orderId}`))
      },
    })
  }
}
